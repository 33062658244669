@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500;700&display=swap');

body {
  background-color: white;
  color: white;


  font-family: 'Quicksand', sans-serif;
  font-weight: 400;

  font-size: medium;

}

.custom-jumbotron {
  background-image: url(/public/background.jpg);
  background-size: cover;
  background-position: center bottom;
}

.jumbotron-text-spacing {
  padding-top: 5em;
}

.navbar-custom {
  background-color: #14202c;
  color: white;
}

.nav-link-custom {
  font-size: 1.25em;
}

.text-black {
  color: black;
}

.img-thumbnail {
  background: rgba(20, 32, 44, 1);
}

.light-blue-card {
  background-color: rgba(20, 32, 44, 1);
  box-shadow: 0 1px 1px 0 rgba(20, 32, 44, 1), 0 -1px 1px 0 rgba(20, 32, 44, 1);
  transition: 0.2s;
}

.light-blue-card:hover {
  box-shadow: 2px 2px 2px 2px rgba(20, 32, 44, 1),
    -2px -2px 2px 2px rgba(20, 32, 44, 1);
}

.btn-primary {
  background-color: #913246 !important;
  border: #913246;
}

.btn-primary:focus {
  box-shadow: 0 0 0.25rem #913246 !important;
}
